class ACVideo {

    static iterate(index, element) {
        ACVideo.videos.push(new ACVideo(element));
    }

    constructor(element) {
        
        this.el = element;
        this.$video = $(element);
        this.$el = $(element);

        this.$el.wrap('<div class="video" tabindex="0" aria-label="Play Video" role="button" aria-pressed="false"></div>');
        this.$el = this.$el.parent();
        this.$el.append('<div class="video__play-icon-wrapper"><svg class="video__play-icon width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg"><path id="Play" d="M66.0385 47.7724C67.3615 48.5442 67.3615 50.4558 66.0385 51.2276L42.0077 65.2455C40.6744 66.0232 39 65.0615 39 63.5179L39 35.4821C39 33.9385 40.6744 32.9767 42.0077 33.7545L66.0385 47.7724Z" fill="#333333"/><path id="Pause" fill-rule="evenodd" clip-rule="evenodd" d="M35 30C33.8954 30 33 30.8954 33 32V68C33 69.1046 33.8954 70 35 70H42C43.1046 70 44 69.1046 44 68V32C44 30.8954 43.1046 30 42 30H35ZM57 30C55.8954 30 55 30.8954 55 32V68C55 69.1046 55.8954 70 57 70H64C65.1046 70 66 69.1046 66 68V32C66 30.8954 65.1046 30 64 30H57Z" fill="#333333"/></svg></div>');

        this.$el.on('click', this.togglePlay.bind(this));
        this.$el.on('keydown', this.onKeyDown.bind(this));
        this.$el.on('keyup', this.onKeyUp.bind(this));
        this.$video.on('pause', this.onPause.bind(this));
        this.$video.on('play', this.onPlay.bind(this));
    }

    togglePlay(event) {

        if ( this.el.paused ) {
            
            this.el.play();
            
        } else {

            this.el.pause();
        }
    }

    onPlay(event) {

        this.$el.addClass('video--is-playing');
        this.$el.attr('aria-pressed', true);
        this.$el.attr('aria-label', 'Pause Video');
    }

    onPause(event) {

        this.$el.removeClass('video--is-playing');
        this.$el.attr('aria-pressed', false);
        this.$el.attr('aria-label', 'Play Video');
    }

    // Keyboard events

    onKeyDown(event) {

        if ( event.keyCode === 32 ) {
            // Prevent Spacebar from scrolling the page
            event.preventDefault();
        } else if (event.keyCode === 13 ) {
            // Toggle on Enter press
            event.preventDefault();
            this.togglePlay(event);
        }
    }

    onKeyUp(event) {
     
        if (event.keyCode === 32 ) {
            // Toggle on Spacebar press
            event.preventDefault();
            this.togglePlay(event);
        }
    }
};

ACVideo.videos = [];

export default ACVideo;