import ACVideo from './lib/ACVideo.js';
import ACLazyLoad from './lib/ACLazyLoad.js';
import ACModal from './lib/ACModal.js';

$(function() {

    console.log("Ready...");

    // Wrap all video elements and add custom play controls
    $('video').each(ACVideo.iterate);

    // LazyLoad selected iamges
    $('.lazy').each(ACLazyLoad.iterate);
    var timer = setTimeout(function() {
        ACLazyLoad.load();
        clearTimeout(timer);
    }, 0);

    // Setup any modal windows
    $('.modal').each(ACModal.iterate);

    // Scroll and reveal instantiation
    ScrollReveal({
        duration: 1000,
        interval: 600,
        distance: '100px'
    });
    ScrollReveal().reveal($('.project-card-list__item'));
    ScrollReveal().reveal($('.project-story').children().not('h6'));
    ScrollReveal().reveal($('.project-story h6'), {
        distance: '0px',
        delay: 400
    });
});