
const _focusable = 'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), [tabindex="0"]';

class ACModal {

    static iterate(index, element) {
        ACModal.modals.push(new ACModal(element));
    }

    constructor(element) {
        
        this.el = element;
        this.$el = $(element);
        this.$window = $('.modal__window', this.$el);
        this.$cover = $('.modal__cover', this.$el);
        this.name = this.$el.attr('name');
        this.$link = $('a[data-modal-link="' + this.name + '"]');
        this.$focusable = $(_focusable, this.$el);
        this.$activeElement = undefined;

        if ( !this.name || this.$link.length == 0 ) {

            // Link not found for modal window. 
            return;
        }

        this.$link.on('click', this.openModal.bind(this));
        this.$cover.on('click', this.closeModal.bind(this));
        this.$el.on('keydown', this.onKeyDown.bind(this));

        this.$activeElement
        $('.login__close', this.$el).on('click', this.closeModal.bind(this));

        if ( this.$el.hasClass('modal--open') ) {
            // This means the modal window is already open
            // so we need to make sure the focus is right
            this.$focusable.get(0).focus();
        }
    }

    openModal(event) {

        event.preventDefault();
        this.$el.addClass('modal--open');

        this.$activeElement = $(document.activeElement);
        this.$focusable.get(0).focus();
    }

    closeModal(event) {

        event.preventDefault();
        this.$el.removeClass('modal--open');

        if ( this.$activeElement ) {
            this.$activeElement.focus();
            this.$activeElement = undefined;
        }
    }

    onKeyDown(event) {

        switch (event.keyCode) {
            case 9: // Tab key

                if ( this.$focusable.length <= 1 ) {

                    event.preventDefault();
                    break;
                }            

                if ( event.shiftKey ) {

                    event.preventDefault();
                    this.focusPreviousElement(document.activeElement);

                } else {

                    event.preventDefault();
                    this.focusNextElement(document.activeElement);
                }

                break;
        
            case 27: // Esc key
                this.closeModal(event);
                break;

            default:
                break;
        }
    }

    focusNextElement(currentElement) {

        var i = this.$focusable.index(currentElement);

        if ( this.$focusable.length > (i + 1) ) {
            i = i + 1;
        } else {
            i = 0;
        }

        this.$focusable.get(i).focus();
    }

    focusPreviousElement(currentElement) {

        var i = this.$focusable.index(currentElement);

        if ( (i - 1) > -1 ) {
            i = i - 1;
        } else {
            i = this.$focusable.length - 1;
        }

        this.$focusable.get(i).focus();
    }
};

ACModal.modals = [];

export default ACModal;