class ACLazyLoad {

    static iterate(index, element) {
        ACLazyLoad.images.push(new ACLazyLoad(element));
    }

    static load() {
        ACLazyLoad.images.forEach(function(el){
            el.load();
        });
    }

    constructor(element) {
        
        this.el = element;
    }

    load() {
        var $sources;

        if ( this.el.nodeName.toLowerCase() == 'picture' ) {
            $sources = $(this.el).children();
        } else {
            $sources = $(this.el);
        }

        $sources.each(function(index, element){
            var $el = $(element);
            $el.attr($el.data());
        });
    }
};

ACLazyLoad.images = [];

export default ACLazyLoad;